import React, { createContext, useState } from "react";

export const emailContext = createContext();

const EmailContext = (props) => {
  const [email, setEmail] = useState("");
  const [responseData, setResponseData] = useState("");

  const contextValue = {
    email,
    setEmail,
    responseData,
    setResponseData,
  };

  return (
    <emailContext.Provider value={contextValue}>
      {props.children}
    </emailContext.Provider>
  );
};

export default EmailContext;
