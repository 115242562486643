import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_SITE_KEY = "6Le-hPApAAAAAGXn_S03U1ZQpradTjqxo4oUBQr2";

const Recaptcha = () => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setRecaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }
    // Submit form or do further processing
    console.log("Form submitted");
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Your form fields */}
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={handleRecaptchaChange}
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default Recaptcha;
