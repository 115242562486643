import React, { useContext, useEffect, useState } from "react";

import { marked } from "marked";
import { TargetedAudience, ToneVoice } from "../data/options";
import { Context } from "../context/context";
import axios from "axios";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
// import { FaEdit } from "react-icons/fa";
// import { IoMdClose } from "react-icons/io";

const URL = process.env.REACT_APP_BACKEND_API_KEY;

const ArticleShow = () => {
  const {
    topic,
    setTopic,
    targetAudience,
    setTargetAudience,
    articleLength,
    setArticleLength,
    toneOfVoice,
    setToneOfVoice,
    generateImage,
    setGenerateImage,
    imgResult,
    isTyping,
    messages,
    handleSubmit,
  } = useContext(Context);

  const [renderedMessages, setRenderedMessages] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [chatgptResponse, setChatGptResponse] = useState("");
  const [renderingComplete, setRenderingComplete] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalTopic, setModalTopic] = useState(topic);

  // const handleToggleModal = () => {
  //   setIsModalOpen(!isModalOpen);
  // };

  const handleInputChange = (e) => {
    const text = e.target.value;

    // Count characters
    const charCount = text.length;
    if (charCount < 256) {
      setTopic(text);
      setWordCount(charCount);
    }
  };

  // const handleModalInputChange = (e) => {
  //   const text = e.target.value;
  //   // Count characters
  //   const charCount = text.length;
  //   if (charCount < 256) {
  //     setModalTopic(text);
  //   }
  // };

  // const handleModalSubmit = (e) => {
  //   e.preventDefault();
  //   setTopic(modalTopic);
  //   handleToggleModal();
  //   // Create a new submit event and dispatch it
  //   const formSubmitEvent = new Event("submit", {
  //     bubbles: true,
  //     cancelable: true,
  //   });
  //   document.querySelector("form").dispatchEvent(formSubmitEvent);
  // };

  const markdownToPlainText = (markdown) => {
    if (!markdown) return "";
    const html = marked(markdown);

    const temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = html;
    return temporaryElement.textContent || temporaryElement.innerText || "";
  };

  const TargetedAudiences = TargetedAudience.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  const ToneVoices = ToneVoice.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  useEffect(() => {
    const storedUser = localStorage.getItem("userData");
    const user = storedUser ? JSON.parse(storedUser) : null;
    const auth_key = user && user.data ? user.data.auth_key : null;

    const saveToDatabase = async (topic, res) => {
      try {
        await axios.post(
          `${URL}store-search-history`,
          {
            search_text: topic,
            response: markdownToPlainText(res),
          },
          {
            headers: {
              authorization: `${auth_key}`,
              "Content-Type": "application/json",
            },
          }
        );
        // console.log("Data saved successfully");
      } catch (error) {
        console.log("Error saving data!!");
      }
    };

    const allResponsesFromChatGPT = messages
      .filter((message) => message.sender === "ChatGPT")
      .every((message) => message.sender === "ChatGPT");

    if (allResponsesFromChatGPT) {
      const res = messages
        .filter((message) => message.sender === "ChatGPT")
        .map((message) => message.message)
        .join("\n");
      if (res.length > 0) {
        saveToDatabase(topic, res);
        setChatGptResponse(res);
      }
    }
  }, [messages, topic]);

  useEffect(() => {
    const renderMessagesWithDelay = async () => {
      const renderPromises = messages.map((message, index) => {
        return new Promise((resolve) => {
          if (message.sender === "ChatGPT") {
            const words = markdownToPlainText(message.message).split(" ");
            words.forEach((word, i) => {
              const endsWithPeriod = word.endsWith(".");
              setTimeout(() => {
                setRenderedMessages((prevMessages) => [
                  ...prevMessages,
                  <span key={`${index}-${i}`}>{word} </span>,
                  endsWithPeriod ? (
                    <>
                      <br />
                      <span
                        style={{ display: "block", marginTop: "0.5em" }}
                      ></span>
                    </>
                  ) : null,
                ]);

                if (i === words.length - 1) {
                  resolve();
                }
              }, index * 750 + i * 50);
            });
          } else {
            setRenderedMessages((prevMessages) => [
              ...prevMessages,
              <span key={`${index}-empty`}></span>,
            ]);
            resolve();
          }
        });
      });

      await Promise.all(renderPromises); // Wait for all messages to be rendered
      setRenderingComplete(true); // Set rendering complete to true
    };

    renderMessagesWithDelay();

    return () => {
      setRenderedMessages([]);
      setRenderingComplete(false); // Reset when messages change
    };
  }, [messages, topic]);

  const handleDownloadDoc = () => {
    const blob = new Blob([markdownToPlainText(chatgptResponse)], {
      type: "application/msword",
    });
    saveAs(blob, "WriteWiseAI.doc");
  };

  return (
    <>
      <section className="article_generator">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="inner_article position-sticky top-0">
                {/* form start  */}
                <form onSubmit={handleSubmit}>
                  <div className="d-flex align-items-center ">
                    <img src="/assets/images/generator-logo.svg" alt="" />
                    <h1 className="m-0 ps-3">Content Generator</h1>
                  </div>
                  <div>
                    <p className="m-0">
                      An content writing generator that assists you in crafting
                      content on any subject while maintaining your desired tone
                      of voice.
                    </p>
                  </div>
                  <div className="what_about pt-5 position-relative ">
                    <h2 className="mb-3">What is your content about?</h2>

                    <textarea
                      type="text"
                      className="form-control"
                      id="topic"
                      name="topic"
                      rows="3"
                      placeholder="Please type your Instructions"
                      onChange={handleInputChange}
                      value={topic}
                    />
                    <span
                      className="position-absolute p-1 text-xs"
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        fontSize: "10px",
                        bottom: "2px",
                        right: "5px",
                      }}
                    >
                      {wordCount}/255
                    </span>
                  </div>
                  <div className="target_audience pt-4">
                    <h2 className="mb-3">Who is your Target Audience?</h2>
                    <select
                      className="form-select form-select-md"
                      aria-label=".form-select-sm example"
                      id="targetAudience"
                      name="targetAudience"
                      required
                      onChange={(e) => setTargetAudience(e.target.value)}
                      value={targetAudience}
                    >
                      {TargetedAudiences}
                    </select>
                  </div>
                  <div className="article_lenght">
                    <h2 className="pt-3 pb-2">Content Length</h2>
                    <div className="d-flex  ">
                      <div className="form-check pe-2 w-100 ">
                        <input
                          className="form-check-input cursor-pointer"
                          type="radio"
                          id="extended"
                          name="articleLength"
                          value="Extended"
                          onChange={(e) =>
                            setArticleLength(e.target.value === "Extended")
                          }
                          checked={articleLength}
                        />
                        <label
                          className="form-check-label cursor-pointer"
                          htmlFor="extended"
                        >
                          Extended
                        </label>
                      </div>
                      <div className="form-check w-100 ">
                        <input
                          className="form-check-input cursor-pointer"
                          type="radio"
                          id="short"
                          name="articleLength"
                          value="Short"
                          onChange={(e) =>
                            setArticleLength(e.target.value === "Extended")
                          }
                          checked={!articleLength}
                        />
                        <label
                          className="form-check-label cursor-pointer"
                          htmlFor="short"
                        >
                          Short
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="target_audience pt-4">
                    <h2 className="mb-3">Your preferred tone of Voice?</h2>
                    <select
                      className="form-select form-select-md"
                      aria-label=".form-select-sm example"
                      id="toneOfVoice"
                      name="toneOfVoice"
                      onChange={(e) => setToneOfVoice(e.target.value)}
                      value={toneOfVoice}
                    >
                      {ToneVoices}
                    </select>
                  </div>
                  <div className="d-flex justify-content-between  article_lenght mt-4">
                    <h2>Generate Image</h2>
                    <div className="form-check pe-2">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        id="yes"
                        name="generateImage"
                        value="Yes"
                        onChange={(e) =>
                          setGenerateImage(e.target.value === "Yes")
                        }
                        checked={generateImage}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="yes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        id="no"
                        name="generateImage"
                        value="No"
                        onChange={(e) =>
                          setGenerateImage(e.target.value === "Yes")
                        }
                        checked={!generateImage}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="no"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  <div className="generate_btn mt-5">
                    <button type="submit" className="w-100 btn">
                      Generate Response
                    </button>
                  </div>
                </form>
                {/* form end  */}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="generated_result pt-5">
                <div className="text_box">
                  ,
                  {messages.length > 1 ? (
                    <>
                      <h1 className="text-black d-flex align-items-center">
                        {topic}{" "}
                        {/* {!isTyping && renderingComplete && (
                          <FaEdit
                            className="mx-2 cursor-pointer"
                            onClick={handleToggleModal}
                          />
                        )} */}
                      </h1>
                      {!isTyping && (
                        <>
                          {renderedMessages.map((message, index) => (
                            <React.Fragment key={index}>
                              {message}
                            </React.Fragment>
                          ))}
                          <br />
                          {renderingComplete && (
                            <>
                              <hr />
                              <FiDownload
                                className="mx-2 cursor-pointer"
                                onClick={handleDownloadDoc}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        className="expty_article_space mt-4 d-flex justify-content-center  align-items-center flex-column "
                        style={{ height: "80vh" }}
                      >
                        <h1 style={{ fontSize: "16px", fontWeight: "500" }}>
                          Create outcomes by completing the form on the left and
                          selecting "Generate Response."
                        </h1>
                        <img
                          src="/assets/images/wallPostAmico1.svg"
                          alt=""
                          width={291}
                          height={291}
                        />
                        <h2
                          style={{
                            fontSize: "24px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Your AI Generated Content will appear here.
                        </h2>
                      </div>
                    </>
                  )}
                  {imgResult && (
                    <img
                      className="result-image"
                      src="/assets/images/wallPostAmico1.svg"
                      alt="Generated"
                      style={{ width: "152px", height: "152px" }}
                    />
                  )}
                  {isTyping && (
                    <div className="text-muted">
                      WriteWiseAI processing something better for you...
                    </div>
                  )}
                  {isTyping && (
                    <div className="loader">
                      <hr className="animated-bg" />
                      <hr className="animated-bg" />
                      <hr className="animated-bg" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div
                className="modal-header d-flex justify-content-between text-white"
                style={{
                  background:
                    "linear-gradient(90deg, #ab4cba 0%, #6f3ab1 100%, #5941ce 100.01%)",
                }}
              >
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Edit
                </h5>
                <button
                  type="button"
                  className="close btn fs-3 text-white"
                  onClick={handleToggleModal}
                  aria-label="Close"
                >
                  <IoMdClose />
                </button>
              </div>
              <form onSubmit={handleModalSubmit}>
                <div className="modal-body">
                  <textarea
                    type="text"
                    className="form-control my-4"
                    id="topic"
                    name="topic"
                    rows="3"
                    placeholder="Write your additional instructions"
                    value={modalTopic}
                    onChange={handleModalInputChange}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn text-white"
                    style={{
                      background:
                        "linear-gradient(90deg, #ab4cba 0%, #6f3ab1 100%, #5941ce 100.01%)",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ArticleShow;
