const TargetedAudience = [
  {
    id: 1,
    label: "Article",
    value: "article",
  },
  {
    id: 2,
    label: "Linkedin Article",
    value: "linkedin article",
  },
  {
    id: 3,
    label: "Facebook",
    value: "facebook",
  },
  {
    id: 4,
    label: "Linkedin Post",
    value: "linkedin post",
  },
  {
    id: 5,
    label: "Instagram",
    value: "instagram",
  },
  {
    id: 6,
    label: "Website Article",
    value: "website article",
  },
  {
    id: 7,
    label: "Research Paper",
    value: "research paper",
  },
];

const ToneVoice = [
  {
    id: 1,
    label: "Standard",
    value: "standard",
  },
  {
    id: 2,
    label: "Fluency",
    value: "fluency",
  },
  {
    id: 3,
    label: "Formal",
    value: "formal",
  },
  {
    id: 4,
    label: "Academic",
    value: "academic",
  },
  {
    id: 5,
    label: "Creative",
    value: "creative",
  },
  {
    id: 6,
    label: "Custom",
    value: "custom",
  },
  {
    id: 7,
    label: "Simple",
    value: "simple",
  },
];

export { TargetedAudience, ToneVoice };
