import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      {/* ======== elevate your Articles start  ============ */}
      <section className="elevate_articles home_page">
        <div className="container">
          <div className="row">
            <div className="col-10 col-lg-8 m-auto text-center">
              <h1>
                <span>Elevate </span> Your Content with{" "}
                <span>Transformative</span> AI Assistant
              </h1>
              <p className="txt">
                Welcome to our cutting-edge AI-powered platform designed to
                revolutionize the way you write content. Our intuitive AI tool
                empowers you to effortlessly craft compelling content with
                precision and efficiency.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4 get_start_btn">
          <Link to="/register">
            <button className="btn-get-started text-white py-3">
              Get Start for free
            </button>
          </Link>
          <Link to="/login">
            <button className="ms-3 btn-login">Login</button>
          </Link>
        </div>
      </section>
      {/* ======== elevate your Articles End  ============ */}
    </>
  );
};

export default Hero;
