import React from "react";
import CustomNavbar from "../Components/Index/CustomNavbar";
import ArticleShow from "../Components/ArticleShow";

const Result = () => {
  return (
    <>
      <CustomNavbar />
      <ArticleShow />
    </>
  );
};

export default Result;
