export const processMessageToChatGPT = async (chatMessages, API_KEY) => {
  const apiMessages = chatMessages.map((messageObject) => {
    const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
    return { role, content: messageObject.message };
  });

  const apiRequestBody = {
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "system",
        content: "You're an expert coder who generate react components.",
        // "I'm a article writer generates articles based on the given topic.",
      },
      ...apiMessages,
    ],
    temperature: 0,
  };

  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + API_KEY,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiRequestBody),
  });
  // console.log("response", response);

  return response.json();
};

const ChatGPTHandler = ({ messages, setMessages, setIsTyping }) => {
  const API_KEY = process.env.REACT_APP_API_KEY;

  const handleSendRequest = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);

    try {
      const response = await processMessageToChatGPT(
        [...messages, newMessage],
        API_KEY
      );
      const content = response.choices[0]?.message?.content;
      if (content) {
        const chatGPTResponse = {
          message: content,
          sender: "ChatGPT",
        };
        setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
      }
    } catch (error) {
      console.error("Error While processing message!!");
    } finally {
      setIsTyping(false);
    }
  };

  return { handleSendRequest };
};

export default ChatGPTHandler;
