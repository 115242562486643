import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { emailContext } from "../../context/EmailContext";
const URL = process.env.REACT_APP_BACKEND_API_KEY;

function Login() {
  const { setResponseData } = useContext(emailContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email_id: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("userData");
    if (storedData) {
      setResponseData(JSON.parse(storedData));
    }
  }, [setResponseData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // const storedUser = localStorage.getItem("userData");
      // const user = storedUser ? JSON.parse(storedUser) : null;
      // const auth_key = user && user.data ? user.data.auth_key : null;

      const response = await axios.post(`${URL}sign-in-with-email`, formData);
      // console.log("Login response:", response);

      if (response.data.message === "Account logged in successfully") {
        setResponseData(response.data);
        localStorage.setItem("userData", JSON.stringify(response.data));

        const storedUser = localStorage.getItem("userData");
        const user = storedUser ? JSON.parse(storedUser) : null;
        const auth_key = user && user.data ? user.data.auth_key : null;
        // console.log(auth_key);
        if (auth_key) {
          const prompt_req_response = await axios.post(
            `${URL}search-session`,
            {},
            {
              headers: {
                authorization: `${auth_key}`,
              },
            }
          );

          // console.log("Session response:", prompt_req_response);

          if (prompt_req_response.data.status === 1) {
            navigate("/chat");
          }
        } else {
          console.log("An error while Logged In!!");
          navigate("/login");
        }
      } else {
        console.log(
          "Invalid session status!!"
          // prompt_req_response.data.status
        );
        localStorage.removeItem("userData");
        navigate("/");
      }
      // } else {
      //   console.log("No valid auth key found.");
      //   navigate("/login");
      // }
    } catch (error) {
      console.error("Error during login process!!");

      if (error.response) {
        if (error.response.data.status === 0) {
          localStorage.removeItem("userData");
          navigate("/");
        } else if (error.response.status === 440) {
          console.error("Error: Status Code 440");
        } else {
          console.error("Unexpected error response!!");
        }
      } else if (error.request) {
        console.log("No response received!!");
      } else {
        console.log("Error in setting up request!!");
      }
    }
  };

  return (
    <section className="bg_img elevate_articles">
      <div className="container">
        <div className="row justify-content-center ">
          <Link className="navbar-brand bgcolor" to="/">
            <p className="m-0 text-dark fw-bolder">WriteWise AI</p>
          </Link>
          <div className=" col-lg-8 col-12 m-auto text-center">
            <div className="inner_create">
              <span>WriteWise.AI</span>
              <h2>It's good to see you back!</h2>
              <p className="m-0">
                Let's continue crafting compelling content together.
              </p>
            </div>
          </div>
        </div>
        <div className="you_info_container login mt-5">
          <form onSubmit={handleSubmit}>
            <div>
              <h2 className="m-0">Welcome Back!</h2>
              <p className="m-0">Welcome back! Please enter your details.</p>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="user@example.com"
                  name="email_id"
                  value={formData.email_id}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mb-3 position-relative ">
              <div className="col-12">
                <label htmlFor="Password" className="form-label">
                  Password
                </label>
                <input
                  id="password-field"
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Create a strong password to secure your account"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  autoComplete="current-password"
                />
                <span
                  className={`fa fa-fw ${
                    passwordVisible ? "fa-eye" : "fa-eye-slash"
                  } field-icon toggle-password`}
                  onClick={togglePasswordVisibility}
                />
                <div className="text-end pt-2 forget_pass">
                  <Link to="/otp">Forgot Password? </Link>
                </div>
              </div>
            </div>
            <div className="mt-lg-5 mt-4">
              <button type="submit" className="w-100">
                Submit
              </button>
            </div>
            <div className="text-center already mt-3">
              <p>
                <span>New user? </span>
                <Link to="/register"> Sign-Up</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;
