// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";

// const PrivateRoute = () => {
//   const storedUser = localStorage.getItem("userData");
//   const user = storedUser ? JSON.parse(storedUser) : null;
//   const auth_key = user && user.data ? user.data.auth_key : null;

//   return auth_key ? <Outlet /> : <Navigate to="/login" replace />;
// };

// export default PrivateRoute;

import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

const PrivateRoute = () => {
  const Navigate = useNavigate;
  const storedUser = localStorage.getItem("userData");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const authKey = user && user.data ? user.data.auth_key : null;

  // Render only once and return the result
  return React.useMemo(() => {
    return authKey ? <Outlet /> : <Navigate to="/login" replace />;
  }, [authKey]);
};

export default PrivateRoute;
