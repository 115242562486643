import React from "react";
import CustomNavbar from "../Components/Index/CustomNavbar";

const History = () => {
  return (
    <>
      <div>
        <CustomNavbar />
        <section className="article_generator">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-lg-4">
                <div className="inner_article">
                  <div className="d-flex align-items-center ">
                    <img
                      src="/assets/images/generator-logo.svg"
                      alt="generator"
                    />
                    <h1 className="m-0 ps-3">Search History</h1>
                  </div>
                  <div className="history_dates pt-4">
                    <h2>Yesterday</h2>
                    <p className="yesterday">
                      The Rise of Remote Work: Trends, Challenges, and
                      Opportunities
                    </p>
                  </div>
                  <div className="history_dates">
                    <h2>Yesterday</h2>
                    <p>
                      The Rise of Remote Work: Trends, Challenges, and
                      Opportunities
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="generated_result pt-5">
                  <div className="social_media_btns">
                    <button>Instagram</button>
                    <button className="ms-3 me-3">Short</button>
                    <button>Standard</button>
                  </div>
                  <span>11057 characters</span>
                  <h1 className="m-0">
                    Embracing the Future: The Rise of Remote Work
                  </h1>
                  <div className="text_box">
                    <p>
                      In the wake of the digital revolution, the landscape of
                      work is undergoing a profound transformation. The rise of
                      remote work, once considered a novel concept, has now
                      become a mainstream phenomenon reshaping the way we
                      perceive and engage with employment. This seismic shift
                      has been accelerated by technological advancements,
                      changing attitudes towards work-life balance, and the
                      recent global events that have propelled remote work into
                      the spotlight.
                    </p>
                    <p>
                      Remote work, also known as telecommuting or telework,
                      refers to the practice of working outside of a traditional
                      office environment, often from home or another location of
                      one's choosing. While the concept of working remotely has
                      been around for decades, recent years have witnessed a
                      significant surge in its adoption across industries
                      worldwide.
                    </p>
                    <p>
                      Maintaining effective communication and collaboration
                      among remote teams can be challenging. Without
                      face-to-face interactions, misunderstandings may arise,
                      and team cohesion may suffer. Employers must invest in
                      suitable communication tools and foster a culture of
                      transparency and inclusivity to overcome these challenges.
                    </p>
                    <p>
                      Maintaining effective communication and collaboration
                      among remote teams can be challenging. Without
                      face-to-face interactions, misunderstandings may arise,
                      and team cohesion may suffer. Employers must invest in
                      suitable communication tools and foster a culture of
                      transparency and inclusivity to overcome these challenges.
                    </p>
                    <p>
                      Maintaining effective communication and collaboration
                      among remote teams can be challenging. Without
                      face-to-face interactions, misunderstandings may arise,
                      and team cohesion may suffer. Employers must invest in
                      suitable communication tools and foster a culture of
                      transparency and inclusivity to overcome these challenges.
                    </p>
                    <p>
                      Maintaining effective communication and collaboration
                      among remote teams can be challenging. Without
                      face-to-face interactions, misunderstandings may arise,
                      and team cohesion may suffer. Employers must invest in
                      suitable communication tools and foster a culture of
                      transparency and inclusivity to overcome these challenges.
                    </p>
                    <p>
                      Maintaining effective communication and collaboration
                      among remote teams can be challenging. Without
                      face-to-face interactions, misunderstandings may arise,
                      and team cohesion may suffer. Employers must invest in
                      suitable communication tools and foster a culture of
                      transparency and inclusivity to overcome these challenges.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default History;
