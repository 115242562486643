import { createContext, useState } from "react";
import ChatGPTHandler from "../ChatGPTHandler";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const URL = process.env.REACT_APP_BACKEND_API_KEY;

export const Context = createContext();

const ContextProvider = (props) => {
  const [prevPrompts, setPrevPrompts] = useState([]);
  const [messages, setMessages] = useState([
    {
      message: "",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [topic, setTopic] = useState([]);
  let [prompt, setPrompt] = useState([]);
  const [recentPrompt, setRecentPrompt] = useState("");
  const [showResult, setShowResult] = useState(false);

  const [targetAudience, setTargetAudience] = useState("article");
  const [articleLength, setArticleLength] = useState(false);
  const [toneOfVoice, setToneOfVoice] = useState("standard");
  const [generateImage, setGenerateImage] = useState(false);
  const [imgResult, setImgResult] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const navigate = useNavigate();

  const handleMessagePrompt = (input) => {
    setPrevPrompts((prev) => [...prev, input]);
  };

  const { handleSendRequest } = ChatGPTHandler({
    messages,
    setMessages,
    setIsTyping,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const storedUser = localStorage.getItem("userData");
      const user = storedUser ? JSON.parse(storedUser) : null;
      const auth_key = user && user.data ? user.data.auth_key : null;

      const response = await axios.post(
        `${URL}search-session`,
        {},
        {
          headers: {
            authorization: `${auth_key}`,
          },
        }
      );

      if (response.data.status === 1) {
        // Proceed with form submission
        const prompt = `Write an content about "${topic}" with sub headings for ${targetAudience} audience with ${
          articleLength ? 300 : 600
        } length, using a ${toneOfVoice} tone of voice. Generate image: ${generateImage}.`;

        setPrompt(prompt);

        setMessages([
          {
            message: "",
            sentTime: "just now",
            sender: "ChatGPT",
          },
        ]);

        setIsTyping(true);
        setShowResult(true);
        handleMessagePrompt(topic);

        await handleSendRequest(prompt);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.status === 0) {
          localStorage.removeItem("userData");
          navigate("/");
        } else if (error.response.status === 440) {
          console.error("Error: Status Code 440");
        }
      } else if (error.request) {
        console.log("Error request data!!");
      } else {
        console.log("An Error Occured while fetching response.");
      }
      console.log("You have reached maximum  error!!");
    } finally {
      setIsTyping(false);
      e.target.reset();
    }
  };

  const contextValue = {
    prevPrompts,
    setPrevPrompts,
    setRecentPrompt,
    handleMessagePrompt,
    topic,
    setTopic,
    targetAudience,
    setTargetAudience,
    articleLength,
    setArticleLength,
    toneOfVoice,
    setToneOfVoice,
    generateImage,
    setGenerateImage,
    prompt,
    setPrompt,
    imgResult,
    setImgResult,
    isTyping,
    setIsTyping,
    messages,
    setMessages,
    handleSubmit,
    showResult,
    recentPrompt,
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};

export default ContextProvider;
