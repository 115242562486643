import React from "react";
import MainNavbar from "../Components/Index/MainNavbar";
import Hero from "../Components/Hero";
import Features from "../Components/Features";
import MainFooter from "../Components/Index/MainFooter";

const Home = () => {
  return (
    <>
      <MainNavbar />
      <Hero />
      <Features />
      <MainFooter />
    </>
  );
};

export default Home;
