import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainNavbar from "../Components/Index/MainNavbar";

const Pricing = () => {
  const [pricingYearly, setPricingYearly] = useState(false);
  const handlePricing = () => {
    setPricingYearly(!pricingYearly);
  };
  return (
    <>
      <MainNavbar />
      {/* ========= wrapper_pricing card Start  */}
      <div className="wrapper_pricing">
        <div className="container-xl">
          <div className="row g-3 justify-content-center ">
            <div className="col-10">
              <div className="row pricing_head">
                <div className="col-12 col-lg-7 text-light  m-auto  text-center ">
                  <h1>Maximize Efficiency and Write with Assurance</h1>
                  <p>
                    Writewise offers plans and pricing tailored to scale with
                    your growing needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="pricing_section">
              <div className="container">
                <div className="row justify-content-center ">
                  <div className="col-lg-10 col-12">
                    <section className="tab_section">
                      <div className="sidebar">
                        <button
                          onClick={handlePricing}
                          className={`tab-btn ${
                            !pricingYearly ? "tab-btn-active" : ""
                          }`}
                          data-for-tab={1}
                        >
                          Monthly
                        </button>
                        <button
                          onClick={handlePricing}
                          className={`tab-btn ${
                            pricingYearly ? "tab-btn-active" : ""
                          }`}
                          data-for-tab={2}
                        >
                          Yearly
                        </button>
                      </div>
                      <div className="tabs">
                        {/* tabs content  */}
                        <div className="content">
                          <div
                            className="tab-content tab-content-active"
                            data-tab={1}
                          >
                            <div className="tab_container">
                              <div className=" active">
                                <div className="row g-5 g-lg-3 justify-content-center ">
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="card flex-column justify-content-between">
                                      <div>
                                        <h2>Intro</h2>
                                        <span>$0</span>
                                        {/* <span className="free">
                                          {!pricingYearly ? " $0" : " $0"}
                                        </span> */}
                                        <p>
                                          For those who are new to AI-powered
                                          solutions
                                        </p>
                                        <div className="card_listing pt-4">
                                          <ul>
                                            <li>
                                              Generate content only in Standard
                                              tone
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="list_lock">
                                          <ul>
                                            <li>
                                              Generate content for social media
                                              audience
                                            </li>
                                            <li>Generate Image</li>
                                            <li>Save Article</li>
                                            <li>Download Image</li>
                                            <li>Access search History</li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="choose_plan">
                                        <Link to="/chat">Choose Plan</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="card monthely  flex-column justify-content-between">
                                      <div>
                                        <h2>Essentials</h2>
                                        <span className="free">
                                          {!pricingYearly ? " $5" : " $50"}
                                        </span>
                                        <span>Free</span>
                                        <p className="m-0">
                                          For entrepreneurs or small teams
                                          aiming to maximize productivity
                                        </p>
                                        <div className="card_listing popular_card pt-3">
                                          <ul>
                                            <li>Choose your desired tone</li>
                                            <li>
                                              Generate content for social media
                                              audience
                                            </li>
                                            <li>Generate Image</li>
                                          </ul>
                                        </div>
                                        <div className="list_lock">
                                          <ul>
                                            <li>Save Article</li>
                                            <li>Download Image</li>
                                            <li>Access search History</li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="choose_plan monthely_btn">
                                        <Link to="/chat">Choose Plan</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="card flex-column justify-content-between">
                                      <div>
                                        <h2>Professional</h2>
                                        <span className="free">
                                          {!pricingYearly ? " $10" : " $100"}
                                        </span>
                                        <span>Free</span>
                                        <p className="m-0">
                                          For expanding teams incorporating AI
                                          into their daily operations
                                        </p>
                                        <div className="card_listing pt-3">
                                          <ul>
                                            <li>Choose your desired tone</li>
                                            <li>Choose desired audience</li>
                                            <li>Generate Image</li>
                                            <li>Save Article</li>
                                            <li>Download Image</li>
                                            <li>Access search History</li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="choose_plan">
                                        <Link to="/chat">Choose Plan</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========== wrapper_pricing Card End  */}
    </>
  );
};

export default Pricing;
