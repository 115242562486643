import React from "react";
import CustomNavbar from "../Components/Index/CustomNavbar";
import ArticleGenerator from "../Components/ArticleGenerator";

const Profile = () => {
  return (
    <>
      <CustomNavbar />
      <ArticleGenerator />
    </>
  );
};

export default Profile;
