import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Result from "./pages/Result";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import YourDetailsInRegistration from "./pages/Auth/YourDetailsInRegistration";
import History from "./pages/History";
import Otp from "./pages/Auth/Otp";
import PrivateRoute from "./PrivateRoute";
import Pricing from "./pages/Pricing";
import Recaptcha from "./pages/Auth/Recaptcha";
import React from "react";

function App() {
  const navigate = useNavigate();
  const storedUser = localStorage.getItem("userData");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const auth_key = user && user.data ? user.data.auth_key : null;

  const isAuthenticated = !!auth_key;

  React.useEffect(() => {
    if (auth_key) {
      navigate("/chat");
    }
  }, [auth_key, navigate]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/chat" /> : <Login />}
        />
        <Route
          path="/register"
          element={isAuthenticated ? <Navigate to="/chat" /> : <Register />}
        />{" "}
        <Route
          path="/your-details"
          element={
            !isAuthenticated ? (
              <YourDetailsInRegistration />
            ) : (
              <Navigate to="/register" />
            )
          }
        />
        <Route
          path="/otp"
          element={isAuthenticated ? <Navigate to="/" /> : <Otp />}
        />
        <Route path="/history" element={<History />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/auth" element={<Recaptcha />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/chat" element={<Result />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
