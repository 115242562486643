import axios from "axios";
const URL = process.env.REACT_APP_BACKEND_API_KEY;

export const sendEmailVerificationOTP = async (email) => {
  try {
    const response = await axios.post(`${URL}send-email-verification-otp`, {
      email_id: email,
    });
    return response;
  } catch (error) {
    console.log("An Error occured while sending otp!!");
    return false;
  }
};
