import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/context";

const CustomNavbar = () => {
  const { prevPrompts } = useContext(Context);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  // extract first and last name first character
  const storedUser = localStorage.getItem("userData");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const name = user?.data?.name;
  const getInitials = (name) => {
    const words = name?.split(" ");
    const initials = words?.map((word) => word?.charAt(0));
    return initials?.join("");
  };
  const initials = getInitials(name);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    navigate("/");
  };

  return (
    <>
      <section className={`header-section ${isScrolled ? "nav-scroll" : ""}`}>
        <div className="container-xl">
          <nav className="navbar navbar-expand-lg p-0">
            <div className="container-fluid">
              <Link className="navbar-brand bgcolor" to="/">
                <h2 className="m-0 text-light ">WriteWise AI</h2>
              </Link>

              <div className="d-flex align-items-center header-btn ms-auto d-block d-lg-none">
                <Link to="#" className="nav-link">
                  <div className="notification-icon">
                    <span className="notf-nub">10</span>
                  </div>
                </Link>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <i className="bx bx-menu-alt-right"></i>
              </button>

              <div
                className="collapse navbar-collapse d-none d-lg-block"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown history_btn d-flex ps-2">
                    <img
                      src="/assets/images/history-icon1.svg"
                      alt="History Icon"
                    />
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={toggleDropdown}
                    >
                      Search History
                    </Link>
                    <ul
                      className="dropdown-menu 
                      "
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="d-flex  justify-content-between most_recent">
                        <li className="dropdown-item most">Most Recent</li>
                        <li>
                          <Link className="dropdown-item" to="#">
                            View All
                          </Link>
                        </li>
                      </div>
                      <div className="history_time">
                        <ul>
                          {prevPrompts && prevPrompts.length > 0 ? (
                            prevPrompts?.map((item, index) => {
                              const words = item.split(" ");
                              const slicedWords = words.slice(0, 5);
                              const displayText = slicedWords.join(" ");
                              const displayTextWithEllipsis =
                                words.length > 5
                                  ? displayText + "..."
                                  : displayText;

                              return (
                                <li key={index}>
                                  <Link className="dropdown-item" to="#">
                                    {displayTextWithEllipsis}
                                  </Link>
                                </li>
                              );
                            })
                          ) : (
                            <li>
                              <Link className="dropdown-item" to="#">
                                No Search Yet!
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </ul>
                  </li>
                </ul>

                <div className="d-flex align-items-center header-btn ms-4">
                  <div className="profil-pic dropdown d-none d-lg-block">
                    <span
                      id="dropdownMenuLink"
                      className="cursor-pointer"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {initials && initials ? initials : "RS"}
                    </span>

                    <ul
                      className="dropdown-menu dropdown-menu-end profile-dropdown mt-2"
                      aria-labelledby="profiledropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="#">
                          <div className="item-dropdown-pro">
                            <div className="d-flex  profile_row align-items-center ">
                              <div className="icon-dop me-2">
                                <img
                                  src="/assets/images/user-profile.svg"
                                  alt="user profile"
                                  height="20px"
                                  width="20px"
                                />
                              </div>
                              <div className="dop-item-name my_profile">
                                <span>My Profile</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <hr className="m-0" />
                      <li>
                        <Link className="dropdown-item" to="#">
                          <div className="item-dropdown-pro">
                            <div className="d-flex  profile_row align-items-center ">
                              <div className="icon-dop me-2">
                                <img
                                  src="/assets/images/lock.svg"
                                  alt="lock"
                                  height="20px"
                                  width="20px"
                                />
                              </div>
                              <div className="dop-item-name my_profile">
                                <span>Change Password</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <hr className="m-0" />
                      <li>
                        <Link className="dropdown-item" to="#">
                          <div className="item-dropdown-pro">
                            <div className="d-flex  profile_row align-items-center ">
                              <div className="icon-dop me-2">
                                <img
                                  src="/assets/images/lock.svg"
                                  alt="lock"
                                  height="20px"
                                  width="20px"
                                />
                              </div>
                              <div className="dop-item-name my_profile">
                                <span>Change Password</span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <hr className="m-0" />
                      <li>
                        <div className="butto-futar mt-2 text-center logout ">
                          <button onClick={handleLogout}>Log out</button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>

      {/* mobile menu  */}
      <div className="mobile-menu d-lg-none">
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              <Link to="/">WriteWise AI</Link>
            </h5>
            <button
              type="button"
              className="close-btn-icon text-reset"
              data-bs-dismiss="offcanvas"
            >
              <i className="bx bx-x"></i>
            </button>
          </div>
          <div className="offcanvas-body p-0">
            <div className="mobile-menu-list">
              <ul className="list-group">
                <Link to="#" className="list-group-item ">
                  My Profile
                </Link>
              </ul>
              <ul className="navbar-nav mb-2 ms-3 mb-lg-0">
                <div className="dropdown prev-search ">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item dropdown history_btn d-flex ps-2 w-50 mt-2">
                      <img
                        src="/assets/images/history-icon1.svg"
                        className="me-2"
                        alt="History Icon"
                      />
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="navbarDropdown-mobile"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={toggleDropdown}
                      >
                        Search History
                      </Link>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown-mobile"
                      >
                        <div className="d-flex justify-content-between most_recent">
                          <li className="dropdown-item most">Most Recent</li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              View All
                            </Link>
                          </li>
                        </div>
                        <div className="history_time">
                          <ul>
                            {prevPrompts && prevPrompts.length > 0 ? (
                              prevPrompts?.map((item, index) => {
                                const words = item.split(" ");
                                const slicedWords = words.slice(0, 5);
                                const displayText = slicedWords.join(" ");
                                const displayTextWithEllipsis =
                                  words.length > 5
                                    ? displayText + "..."
                                    : displayText;

                                return (
                                  <li key={index}>
                                    <Link className="dropdown-item" to="#">
                                      {displayTextWithEllipsis}
                                    </Link>
                                  </li>
                                );
                              })
                            ) : (
                              <li className="dropdown-item">
                                <Link to="#" className="nav-link">
                                  No Search Yet!
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      </ul>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            <div className="d-flex mobile-btn mobile-nav gap-3 text-center justify-content-center ">
              <button className="nav-link signin" onClick={handleLogout}>
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomNavbar;
