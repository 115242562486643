import React from "react";
import { Link } from "react-router-dom";

const MainFooter = () => {
  return (
    <>
      {" "}
      <section className="footer_section">
        <footer>
          <div className="container">
            <div className="d-flex justify-content-lg-between justify-content-md-between justify-content-sm-between text-center align-items-center flex-wrap justify-content-center ">
              <div className="copy_right">
                <p className="m-lg-0 m-md-0">
                  &copy;2024 WriteWise, Inc. All rights reserved
                </p>
              </div>
              <div className="social_icon">
                <Link to="#">
                  <img
                    src="assets/images/facebook.svg"
                    alt="facebook"
                    title="facebook"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="/assets/images/linkedin.svg"
                    alt="twitter"
                    title="twitter"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="/assets/images/instagram.svg"
                    alt="instagram"
                    title="instagram"
                  />
                </Link>
                <Link to="#">
                  <img
                    src="/assets/images/twitter.svg"
                    alt="linkedin"
                    title="linkedin"
                  />
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};

export default MainFooter;
