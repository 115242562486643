import axios from "axios";
import React, { useContext, useState } from "react";
import { emailContext } from "../../context/EmailContext";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const URL = process.env.REACT_APP_BACKEND_API_KEY;

const YourDetailsInRegistration = () => {
  const { email, setResponseData } = useContext(emailContext);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email_id: email,
    country_code: "+1",
    profession: "",
    password: "",
  });
  const [phone, setPhone] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fullName = `${first_name} ${last_name}`;
    try {
      const response = await axios.post(`${URL}create-user-profile`, {
        ...formData,
        name: fullName,
        phone_number: phone,
      });

      setResponseData(response.data);
      localStorage.setItem("userData", JSON.stringify(response.data));
      navigate("/pricing", { replace: true });
    } catch (error) {
      console.log("An occured while Adding User!!");
    }
  };

  return (
    <>
      <section className="bg_img elevate_articles">
        <div className="container">
          <div className="row justify-content-center ">
            <Link className="navbar-brand bgcolor" to="/">
              <p className="m-0 text-dark fw-bolder">WriteWise AI</p>
            </Link>
            <div className=" col-lg-8 col-12 m-auto text-center">
              <div className="inner_create">
                <span>WriteWise.AI</span>
                <h2>Let’s Create Your Free Account</h2>
                <p className="m-0">
                  Start for Free and Harness AI to Craft Compelling Content
                </p>
              </div>
            </div>
          </div>
          <div className="you_info_container mt-5">
            <form onSubmit={handleSubmit}>
              <div>
                <h2 className="m-0">Your Details!</h2>
                <p className="m-0">
                  Please fill in your details to create your account.
                </p>
              </div>
              <div className="row mb-3">
                <label htmlFor="fullname" className="form-label">
                  Full Name
                </label>
                <div className="col-6">
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    placeholder="First Name"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    placeholder="Last Name"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3 phone-number">
                <label htmlFor="PhoneNumber" className="form-label">
                  Phone Number
                </label>

                <div className="col-12">
                  <PhoneInput
                    className="phone"
                    country={"us"}
                    value={phone}
                    onChange={(phone) => {
                      setPhone(phone);
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <select
                    name="profession"
                    className="form-select form-select-md"
                    aria-label=".form-select-sm example"
                    value={formData.profession}
                    onChange={handleChange}
                  >
                    <option value="">Your Profession (optional)</option>
                    <option value="student">Student</option>
                    <option value="teacher">Teacher</option>
                    <option value="developer">Content Creator</option>
                  </select>
                </div>
              </div>
              <div className="row mb-3 position-relative">
                <div className="col-12">
                  <label htmlFor="Password" className="form-label">
                    Create Password
                  </label>
                  <input
                    id="password-field"
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    className="form-control"
                    placeholder="Create a strong password to secure your account"
                    value={formData.password}
                    onChange={handleChange}
                    autoComplete="current-password"
                  />
                  <span
                    className={`fa fa-fw ${
                      passwordVisible ? "fa-eye" : "fa-eye-slash"
                    } field-icon toggle-password`}
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </div>
              <div className="mt-lg-5 mt-4">
                <button type="submit" className=" w-100">
                  Sign Up
                </button>
              </div>
              <div className="text-center already mt-3">
                <p>
                  <span>Already have an account? </span>
                  <Link to="/login">Login</Link>
                </p>
              </div>
            </form>
          </div>

          <div className="privacy_policy m-0 ">
            <p className="text-center mt-5">
              By signing up you agree to the{" "}
              <Link to="#">Terms of Service</Link> and
              <Link to="#">Privacy Policy</Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default YourDetailsInRegistration;
