import React from "react";

const ArticleGenerator = () => {
  return (
    <>
      <section className="article_generator">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="inner_article">
                <div className="d-flex align-items-center ">
                  <img src="assets/images/generator-logo.svg" alt="" />
                  <h1 className="m-0 ps-3">Content Generator</h1>
                </div>
                <div>
                  <p className="m-0">
                    An content writing generator that assists you in crafting
                    content on any subject while maintaining your desired tone
                    of voice.
                  </p>
                </div>
                <div className="what_about pt-5">
                  <h2 className="mb-3">What is your content about?</h2>
                  <input
                    type="text"
                    placeholder="Please type your topic here"
                  />
                </div>
                <div className="target_audience pt-4">
                  <h2 className="mb-3">Who is your Target Audience?</h2>
                  <select
                    className="form-select form-select-md"
                    aria-label=".form-select-sm example"
                  >
                    <option selected>Instagram</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="article_lenght">
                  <h2 className="pt-3 pb-2">Content Length</h2>
                  <div className="d-flex">
                    <div className="form-check pe-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="extended"
                      />
                      <label className="form-check-label" for="extended">
                        Extended
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="short"
                      />
                      <label className="form-check-label" for="short">
                        Short
                      </label>
                    </div>
                  </div>
                </div>
                <div className="target_audience pt-4">
                  <h2 className="mb-3">Your preferred tone of Voice?</h2>
                  <select
                    className="form-select form-select-md"
                    aria-label=".form-select-sm example"
                  >
                    <option selected>Formal</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="d-flex justify-content-between  article_lenght mt-4">
                  <h2>Generate Image</h2>
                  <div className="d-flex">
                    <div className="form-check pe-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="yes"
                      />
                      <label className="form-check-label" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="no"
                      />
                      <label className="form-check-label" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="generate_btn mt-5">
                  <button className="w-100 btn">Generate Response</button>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="inner_generator text-center ">
                <p className="pt-5 pt-lg-0">
                  Create outcomes by completing the form on the left and
                  selecting "Generating Response."
                </p>
                <h2 className="pt-3">
                  Your AI Generated Content will appear here.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArticleGenerator;
