import React from "react";

const Features = () => {
  return (
    <>
      {/* ========== effortless_section Start =========== */}
      <section className="effortless_section">
        <div className="container">
          <div className="row effortless_title">
            <div className=" col-lg-7 col-11 m-auto text-center">
              <h2>
                Effortless
                <span />
                Seamless
                <span />
                Efficient
              </h2>
              <p>
                With advanced algorithms and natural language processing
                capabilities, we ensure that your articles are not only
                well-written but also tailored to your specific needs and
                audience.
              </p>
            </div>
          </div>
          <section className="row_bg">
            <div className="row g-3 justify-content-center ">
              <div className="col-xl-4 col-md-12 ">
                <div className="inner_card">
                  <div className="d-flex align-items-center card_box">
                    <div className="logo">
                      <img
                        src="/assets/images/search.svg"
                        alt="Target Audience"
                        title="Target Audience"
                      />
                    </div>
                    <div className="ps-3">
                      <h2 className="m-0">Target Audience</h2>
                    </div>
                  </div>
                  <div>
                    <p>
                      This targeted approach boosts engagement, relevance, and
                      article impact, while also offering valuable audience
                      insights for improved content strategy and optimization.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-12 ">
                <div className="inner_card">
                  <div className="d-flex align-items-center card_box">
                    <div className="logo">
                      <img
                        src="/assets/images/tone.svg"
                        alt="tone"
                        title="Tone Selection"
                      />
                    </div>
                    <div className="ps-3">
                      <h2 className="m-0">Tone Selection</h2>
                    </div>
                  </div>
                  <div>
                    <p>
                      Choose from a range of predefined tones including formal,
                      conversational, persuasive, informative, and more,
                      ensuring your article matches the desired style and
                      audience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-12 ">
                <div className="inner_card">
                  <div className="d-flex align-items-center card_box">
                    <div className="logo">
                      <img
                        src="/assets/images/paraphase.svg"
                        alt="Generation"
                        title="Image Generation"
                      />
                    </div>
                    <div className="ps-3">
                      <h2 className="m-0">Image Generation</h2>
                    </div>
                  </div>
                  <div>
                    <p>
                      Create stunning images, each image aligns seamlessly with
                      your narrative, saving you time and effort. Elevate your
                      articles effortlessly, captivating your audience with
                      visually compelling elements that bring your ideas to
                      life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* ========== effortless_section End =========== */}
    </>
  );
};

export default Features;
