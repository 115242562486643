import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { emailContext } from "../../context/EmailContext";
import { sendEmailVerificationOTP } from "./handler";

const Register = () => {
  const { email, setEmail } = useContext(emailContext);
  const [message, setMessage] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await sendEmailVerificationOTP(email);
      if (
        res.data &&
        res.data.message === "User with this email already exists"
      ) {
        setMessage("Email is already registered.");
        navigate("/register", { replace: true });
      } else {
        navigate("/otp", { replace: true });
      }
    } catch (error) {
      console.log("An error occured while sending otp!!");
    }
  };

  return (
    <>
      <section className="bg_img elevate_articles">
        <div className="container">
          <div className="row justify-content-center ">
            <Link className="navbar-brand bgcolor" to="/">
              <p className="m-0 text-dark fw-bolder">WriteWise AI</p>
            </Link>
            <div className=" col-lg-8 col-12 m-auto text-center">
              <div className="inner_create">
                <span>WriteWise.AI</span>
                <h2>Let’s Create Your Free Account</h2>
                <p className="m-0">
                  Start for Free and Harness AI to Craft Compelling Content
                </p>
              </div>
            </div>
          </div>
          <div className="you_info_container mt-5">
            <form onSubmit={handleSubmit}>
              <div>
                <h2 className="m-0">Welcome!</h2>
                <p className="m-0">
                  Enter your email address. We will send a verification code to
                  your email.
                </p>
              </div>
              <div className="row mb-5">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <div className="col-12">
                  <input
                    type="text"
                    id="email"
                    className="form-control"
                    placeholder="user@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {message && <div>{message}</div>}
              </div>
              <div className="mt-lg-5 mt-4">
                <button className="w-100">Sign Up</button>
              </div>
              <div className="text-center already mt-3">
                <p>
                  <span>Already have an account? </span>
                  <Link to="/login">Login</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
