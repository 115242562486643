import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MainNavbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div>
        {/* =========Header Section=== */}
        <section className={`header-section ${isScrolled ? "nav-scroll" : ""}`}>
          <div className="container-xl">
            <nav className="navbar navbar-expand-lg p-0">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <h2 className="m-0">WriteWise AI</h2>
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  aria-controls="offcanvasExample"
                >
                  <i className="bx bx-menu-alt-right" />
                </button>
                <div
                  className="collapse navbar-collapse d-none d-lg-block"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav justify-content-end w-100  mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link" to="/pricing">
                        Pricing
                      </Link>
                    </li>
                    <li className="nav-item  pe-4">
                      <Link className="nav-link" to="/login">
                        Login
                      </Link>
                    </li>
                    <div className="get_started_btn">
                      <Link to="/register" className="nav-link get-quote">
                        Get Started
                      </Link>
                    </div>
                  </ul>
                </div>
              </div>
            </nav>
            {/*--- nav End--- */}
          </div>
        </section>
        {/* =========Header Section End=== */}
        {/* ===========Mobile Menu Start===== */}
        <div className="mobile-menu d-lg-none">
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            data-bs-backdrop="static"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                <picture>
                  <h2 className="m-0 text-start fs-6 fw-bold">WriteWise AI</h2>
                </picture>
              </h5>
              <button
                type="button"
                className="close-btn-icon text-reset"
                data-bs-dismiss="offcanvas"
              >
                <i className="bx bx-x" />
              </button>
            </div>
            <div className="offcanvas-body p-0">
              <div className="mobile-menu-list">
                <ul className="list-group">
                  <Link to="/pricing" className="list-group-item ">
                    Pricing
                  </Link>
                </ul>
              </div>
              <div className="d-flex mobile-btn gap-3 text-center">
                <Link to="/login" className="nav-link signin">
                  Login
                </Link>
                <Link to="/register" className="nav-link get-quote">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* ===========Mobile Menu End===== */}
      </div>
    </>
  );
};

export default MainNavbar;
