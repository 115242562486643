import React, { useContext, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { emailContext } from "../../context/EmailContext";
import { sendEmailVerificationOTP } from "./handler";
import OtpInput from "react-otp-input";

const URL = process.env.REACT_APP_BACKEND_API_KEY;

const Otp = () => {
  const navigate = useNavigate();
  const { email } = useContext(emailContext);
  const [message, setMessage] = useState(false);

  const [otp, setOtp] = useState("");

  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${URL}verify-email-otp`, {
        email_id: email,
        otp: otp,
      });
      if (
        response &&
        response.data &&
        response.data.message === "OTP is incorrect, please provide valid otp."
      ) {
        setMessage("OTP is incorrect");
        navigate("/otp", { replace: true });
      } else {
        navigate("/your-details", { replace: true });
      }
    } catch (error) {
      setError("Invalid OTP. Please try again.");
    }
  };

  const resendEmail = async (e) => {
    try {
      const res = await sendEmailVerificationOTP(email);
      if (res.data) {
        navigate("/otp", { replace: true });
      } else {
        navigate("/register");
      }
    } catch (error) {
      console.log("An error occurred resending OTP!!");
    }
  };

  return (
    <>
      <section className="bg_img elevate_articles">
        <div className="container">
          <div className="row justify-content-center ">
            <Link className="navbar-brand bgcolor" to="/">
              <p className="m-0 text-dark fw-bolder">WriteWise AI</p>
            </Link>
            <div className=" col-lg-8 col-12 m-auto text-center">
              <div className="inner_create">
                <span>WriteWise.AI</span>
                <h2>Let’s Create Your Free Account</h2>
                <p className="m-0">
                  Start for Free and Harness AI to Craft Compelling Content
                </p>
              </div>
            </div>
          </div>
          <div className="you_info_container otp mt-5">
            <form onSubmit={handleSubmit}>
              <div>
                <h2 className="m-0">Verification!</h2>
                <p className="m-0">
                  We have sent a verification code to {email}
                </p>
                <Link to="/register">Edit email address</Link>
              </div>
              <div className="d-flex justify-content-center opt_box pb-2 pt-4 flex-wrap opt-box">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: "50px",
                    height: "50px",
                    fontSize: "20px",
                    margin: "0 5px",
                    textAlign: "center",
                  }}
                />
              </div>

              <div className="text-end pt-3">
                <p className="m-0">
                  Didn’t Receive Code?{" "}
                  <Link to="/otp" onClick={resendEmail}>
                    Resend
                  </Link>
                </p>
              </div>
              {message && <div>{message}</div>}
              {error && <p className="text-danger">{error}</p>}
              <div className="mt-lg-5 mt-4">
                <button className="w-100">Submit</button>
              </div>
              <div className="text-center already mt-3">
                <p>
                  <span>Already have an account? </span>
                  <Link to="/login">Login</Link>
                </p>
              </div>
            </form>
          </div>
          <div className="privacy_policy m-0 ">
            <p className="text-center mt-5">
              By signing up you agree to the{" "}
              <Link to="#">Terms of Service</Link> and{" "}
              <Link to="#">Privacy Policy</Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Otp;
